import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import GoogleMap from './GoogleMap';
import Footer from '../../components/Footer';
import { Styles } from './styles/contact.js';

function Contact() {
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Send Message");
    const [notification, setNotification] = useState("");

    useEffect(() => {
        const form = document.getElementById("form_contact");
        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const formData = new FormData(form);
            const name = formData.get('name').trim();
            const email = formData.get('email').trim();
            const subject = formData.get('subject').trim();
            const message = formData.get('message').trim();

            if (!validateForm({ name, email, subject, message })) return;

            setIsLoading(true);
            setButtonText("Sending...");

            fetch('http://139.59.94.26:3005/send-email2', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, subject, message }),
            })
            .then(response => response.text())
            .then(data => {
                setIsLoading(false);
                setButtonText("Send Request");
                if (data.includes('Email sent')) {
                    showNotification("Email sent successfully!");
                    form.reset();
                } else {
                    showNotification("Failed to send email. Please try again.");
                }
            })
            .catch(error => {
                setIsLoading(false);
                setButtonText("Send Request");
                showNotification("Failed to send email. Please try again.");
            });
        }

        function validateForm(fields) {
            let isValid = true;
            for (const [key, value] of Object.entries(fields)) {
                if (!value) {
                    setError(key, `${key.charAt(0).toUpperCase() + key.slice(1)} can't be blank`);
                    isValid = false;
                } else {
                    setSuccess(key);
                }
            }
            return isValid;
        }

        function setError(key, message) {
            const input = document.getElementById(`contact_${key}`);
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(key) {
            const input = document.getElementById(`contact_${key}`);
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function showNotification(message) {
            setNotification(message);
            setTimeout(() => {
                setNotification("");
            }, 5000);
        }
    }, []);

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Contact Us" />
                {notification && (
                <div style={notificationStyles}>
                    <span>{notification}</span>
                    <button onClick={() => setNotification("")} style={closeButtonStyles}>X</button>
                </div>
            )}
                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="contact-box-title">
                                    <h4>Contact Info</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-map-marker"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Our Location</h5>
                                        <p>Near Landorkhori garden, Mohadi road, Raisoni nagar - Jalgaon.</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-envelope-open"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Email Address</h5>
                                        <p><a style={{display:'inline-block'}} href="mailto:jkenglishschool@gmail.com"><p>jkenglishschool@gmail.com</p></a><br /><a href="mailto:jyotishrivastava38@gmail.com"><p>jyotishrivastava38@gmail.com</p></a></p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Phone Number</h5>
                                        <p><a style={{display:'inline-block'}} href="tel:+918669010117"><p>(+91) 8669010117</p></a><br /><a href="tel:+918669010117"><p>(+91) 9421513262</p></a></p>
                                    </div>
                                </div>
                                <div className="contact-social">
                                    <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/people/JK-English-School-Mohadi-Road-Jalgaon/100063862781481/'><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href='/contact'><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@krishnashrivastava9750'><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://in.linkedin.com/in/sanskriti-shrivastava-998437211'><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href='/contact'><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Get In Touch</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Full Name" id="contact_name" name="name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="email" placeholder="Email Address" id="contact_email" name="email" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Subject" id="contact_subject" name="subject" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea name="message" id="contact_message" placeholder="Enter Message"></textarea>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12" className="text-center">
                                                    <button type="submit" disabled={isLoading}>{buttonText}</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {/* Google Map */}
                    <iframe
            title="JK English School"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.413913290653!2d75.54582767601886!3d20.976038989585778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90e3f2b990949%3A0xdbbd27c000feb1be!2sJK%20English%20School!5e0!3m2!1sen!2sin!4v1718286418318!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0, width:'100vw'}}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
                    {/* <GoogleMap /> */}
                </section>

                {/* Footer 2 */}
                <Footer />

            </div>
            
        </Styles>
    )
}

const notificationStyles = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#4BB543',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px'
};

const closeButtonStyles = {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer'
};

export default Contact
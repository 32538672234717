import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/account.js';

function Register() {
    const [notification, setNotification] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Register Now");

    useEffect(() => {
        const form = document.getElementById("form_registration");
        const fname = document.getElementById("registration_fname");
        const lname = document.getElementById("registration_lname");
        const email = document.getElementById("registration_email");
        const user = document.getElementById("registration_user");
        const password = document.getElementById("registration_password");
        const cpassword = document.getElementById("registration_cpassword");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const fnameValue = fname.value.trim();
            const lnameValue = lname.value.trim();
            const emailValue = email.value.trim();
            const userValue = user.value.trim();
            const passwordValue = password.value.trim();
            const cpasswordValue = cpassword.value.trim();

            if (fnameValue === "") {
                setError(fname, "First name can't be blank");
            } else {
                setSuccess(fname);
            }

            if (lnameValue === "") {
                setError(lname, "Last name can't be blank");
            } else {
                setSuccess(lname);
            }

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }

            if (userValue === "") {
                setError(user, "User name can't be blank");
            } else {
                setSuccess(user);
            }

            if (passwordValue === "") {
                setError(password, "Password can't be blank");
            } else {
                setSuccess(password);
            }

            if (cpasswordValue === "" || passwordValue !== cpasswordValue) {
                setError(cpassword, "Password doesn't match");
            } else {
                setSuccess(cpassword);
            }

            if (
                fnameValue &&
                lnameValue &&
                emailValue &&
                isEmail(emailValue) &&
                userValue &&
                passwordValue &&
                passwordValue === cpasswordValue
            ) {
                setIsLoading(true);
                setButtonText("Registering...");

                fetch('http://139.59.94.26:3005/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        firstName: fnameValue,
                        lastName: lnameValue,
                        email: emailValue,
                        username: userValue,
                        password: passwordValue,
                    }),
                })
                .then(response => response.text())
                .then(data => {
                    setIsLoading(false);
                    setButtonText("Register Now");
                    if (data.includes('Registration successful')) {
                        showNotification("Registration successful! Confirmation email sent.");
                        form.reset();
                    } else {
                        showNotification("Failed to register. Please try again.");
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    setButtonText("Register Now");
                    showNotification("Failed to register. Please try again.");
                });
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

        function showNotification(message) {
            setNotification(message);
            setTimeout(() => {
                setNotification("");
            }, 5000);
        }
    }, []);

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper registration-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Registration" />

                {/* Registration Area */}
                <section className="registration-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="registration-box">
                                    <div className="registration-title text-center">
                                        <h3>Registration</h3>
                                    </div>
                                    <form id="form_registration" className="form">
                                        <p className="form-control">
                                            <label htmlFor="registration_fname">First Name</label>
                                            <input type="text" placeholder="First name" id="registration_fname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_lname">Last Name</label>
                                            <input type="text" placeholder="Last name" id="registration_lname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_email">Email Address</label>
                                            <input type="email" placeholder="Email address" id="registration_email" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_user">User Name</label>
                                            <input type="text" placeholder="Username" id="registration_user" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_password">Password</label>
                                            <input type="password" placeholder="*******" id="registration_password" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_cpassword">Confirm Password</label>
                                            <input type="password" placeholder="Confirm password" id="registration_cpassword" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <button type="submit" disabled={isLoading}>{buttonText}</button>
                                    </form>
                                    <div className="have_account-btn text-center">
                                        <p>Already have an account? <Link to="#">Login Here</Link></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {notification && (
                    <div style={notificationStyles}>
                        <span>{notification}</span>
                        <button onClick={() => setNotification("")} style={closeButtonStyles}>X</button>
                    </div>
                )}

                {/* Footer 2 */}
                <Footer />

            </div>
        </Styles>
    );
}

const notificationStyles = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#4BB543',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px'
};

const closeButtonStyles = {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    marginLeft: '10px',
    cursor: 'pointer'
};

export default Register;
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/newsletterForm.js";

function NewsletterForm() {
    let data = {
        secTitle: 'Get The Latest News & Updates On Your Box',
    }

    const [notification, setNotification] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Subscribe Now");

    useEffect(() => {
        const form = document.getElementById("form2");
        const name = document.getElementById("name2");
        const email = document.getElementById("email2");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const nameValue = name.value.trim();
            const emailValue = email.value.trim();

            if (nameValue === "") {
                setError(name, "Name can't be blank");
            } else {
                setSuccess(name);
            }

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }

            if (nameValue && emailValue && isEmail(emailValue)) {
                setIsLoading(true);
                setButtonText("Subscribing...");

                // Send email request
                fetch('http://139.59.94.26:3005/subscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: nameValue,
                        email: emailValue,
                    }),
                })
                .then(response => response.text())
                .then(data => {
                    setIsLoading(false);
                    setButtonText("Subscribe Now");
                    if (data.includes('Subscription successful')) {
                        showNotification("Subscription successful! Confirmation email sent.");
                        form.reset();
                    } else {
                        showNotification("Failed to subscribe. Please try again.");
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    setButtonText("Subscribe Now");
                    showNotification("Failed to subscribe. Please try again.");
                });
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg2");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

        function showNotification(message) {
            setNotification(message);
            setTimeout(() => {
                setNotification("");
            }, 5000);
        }
    }, []);

    return (
        <Styles>
            {/* Newsletter Form */}
            <section className="newsletter-form-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="newsletter-container">
                                <div className="newsletter-box">
                                    <div className="sec-title text-center">
                                        <h4>{data.secTitle}</h4>
                                    </div>
                                    <form id="form2" className="form">
                                        <Row>
                                            <Col md="4">
                                                <p className="form-control">
                                                    <input type="text" placeholder="Enter your Name" id="name2" />
                                                    <span className="input-msg2"></span>
                                                </p>
                                            </Col>
                                            <Col md="4">
                                                <p className="form-control">
                                                    <input type="email" placeholder="Enter your Email" id="email2" />
                                                    <span className="input-msg2"></span>
                                                </p>
                                            </Col>
                                            <Col md="4">
                                                <button type="submit" disabled={isLoading}>{buttonText}</button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {notification && (
                    <div style={notificationStyles}>
                        <span>{notification}</span>
                        <button onClick={() => setNotification("")} style={closeButtonStyles}>X</button>
                    </div>
                )}
            </section>
        </Styles>
    );
}

const notificationStyles = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#4BB543',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px'
};

const closeButtonStyles = {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    marginLeft: '10px',
    cursor: 'pointer'
};

export default NewsletterForm;
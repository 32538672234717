import React, { Component } from 'react';
import Datas from '../data/footer/footer.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1" style={{ backgroundImage: `url(assets/images/${process.env.PUBLIC_URL + Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="footer-logo-info">
                                <Link to={process.env.PUBLIC_URL + "/"}><img width={200} height={50} style={{border:'1px solid #10b275', padding:'2px', borderRadius:'10px', boxShadow:'2px 2px 4px rgba(16, 178, 117, 0.5)', background:'white'}} src={process.env.PUBLIC_URL + "/assets/images/logo6.svg"} alt="" /></Link>
                                    <p>Mission - To motivate our children to achieve high potential in academics, activity, arts and athletics through challenging learning, experiences and good teaching.</p>
                                    <ul className="list-unstyled">
                                        <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <i className="las la-map-marker" style={{ marginRight: '5px' }}></i>
                                            <span style={{ display: 'inline-block' }}>
                                                Near Landorkhori garden, Mohadi road, Raisoni nagar - Jalgaon
                                            </span>
                                        </li>
                                        <li><i className="las la-envelope"></i>jyotishrivastava38@gmail.com</li>
                                        <li><i className="las la-phone"></i>+91 8669010117</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="5">
                                <div className="f-links">
                                    <h5>Video Links</h5>
                                    <ul className="list-unstyled">
                                        <li><a href="https://www.facebook.com/100063862781481/videos/excellent-performance-of-our-students-in-the-lotus-feet-of-shreeram-taught-by-sa/677119551160864/?_rdr"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Lotus feet</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/3357032491179908?__so__=permalink"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>23rd Annual Day</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/2075844009284389?__so__=permalink"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Mud day celebration</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/1932061407250985?__so__=permalink"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Janmashtami celebration</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/fancy-dress-performance/1198146970661461"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Fancy dress</a></li>
                                    </ul>
                                    <ul className="list-unstyled">
                                    <li><a href="https://www.facebook.com/people/JK-English-School-Mohadi-Road-Jalgaon/100063862781481/?sk=videos"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Patriotic Song</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/bhagwad-geeta-shlok/692404311293695"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Bhagwad geeta shlok</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/students-learning-through-experience/810457496132605"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Students learning</a></li>
                                        <li><a href="https://www.facebook.com/100063862781481/videos/shushan-kids-leading-playgroup-in-jalgaon-we-started-playgroup-since-2000-there-/274050753194997"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>SHUSHAN - Leading playgroup</a></li>
                                        <li><a href="https://www.youtube.com/watch?v=0tLRadvRA1g"
                                            target="_blank" rel="noopener noreferrer">
                                            <i className="las la-angle-right"></i>Patriotic Dance</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="f-post">
                                    <h5>Recent Visits</h5>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/visit1.jpg"} alt="" />
                                        </div>
                                        <div className="post-content">
                                            <Link>Renowned artist inaugurated the school art exhibition.</Link>
                                            <span>May 30, 2023</span>
                                        </div>
                                    </div>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/visit2.jpg"} alt="" />
                                        </div>
                                        <div className="post-content">
                                            <Link>Principal honored students for their academic excellence.</Link>
                                            <span>July 20, 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="copy-text">
                                    <p> 2024 | Designed With <i className="las la-heart"></i> by <a href="https://www.instagram.com/shubham.pingle/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" rel="noopener noreferrer">Shubham Pingle</a></p>
                                </div>
                            </Col>
                            <Col md="6" className="text-right">
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/people/JK-English-School-Mohadi-Road-Jalgaon/100063862781481/'><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://in.linkedin.com/in/sanskriti-shrivastava-998437211'><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@krishnashrivastava9750'><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href='/'><i className="fab fa-dribbble"></i></a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop/>
                </section>
            </Styles>
        )
    }
}

export default Footer

import React, { Component } from 'react';
import Datas from '../data/icon-box/icon-box.json';
import {  Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/iconBox.js";

class IconBox extends Component {
    render() {
        return (
            <Styles>
                {/* Icon Box */}
                <section className="icon-box-area">
                    {/* <Container> */}
                    <div style={{ display: 'flex',justifyContent: 'center' }}>
                            <h3 className='mobileHeading'>'AAAA' School</h3>
                            </div>
                        <Row style={{ margin: '0 10vw' }}>
                            
                            {
                                Datas.map((data, i) => (
                                    <Col md="3" key={i}>
                                        <div className="full-icon-box">
                                            <div style={{boxShadow:"2px 2px 4px rgba(0, 0, 0, 0.5)"}} className="icon-box d-flex">
                                                <div className={data.uniqClass}>
                                                    <i className={data.boxIcon}></i>
                                                </div>
                                                <div className="box-title">
                                                    <h6>{data.title}</h6>
                                                    <p>{data.subTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    {/* </Container> */}
                </section>
            </Styles>
        )
    }
}

export default IconBox

import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Why JK English School <i class="fa-solid fa-question"></i></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Our Mission <i class="fa-solid fa-bullseye"></i></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Our Vision <i class="fa-solid fa-eye"></i></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking <i class="fa-solid fa-ranking-star"></i></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research <i class="fa-solid fa-book-open-reader"></i></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Why JK English School</h4>
                                            <p className="tab-desc">At JK English School, we are dedicated to providing a holistic and enriching educational experience. Our commitment to excellence is reflected in every aspect of our school community, from our mission and vision to our outstanding rankings and research initiatives.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>State-of-the-art facilities and resources for comprehensive learning.</li>
                                                <li><i className="fa fa-check"></i>Commitment to academic and personal excellence.</li>
                                                <li><i className="fa fa-check"></i>Supportive and inclusive school community.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Our Mission</h4>
                                            <p className="tab-desc">To motivate our children to achieve high potential in academics, activity, arts and athletics through challenging learning experiences and good teaching.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Dedicated to fostering a love for learning in every student.</li>
                                                <li><i className="fa fa-check"></i>Providing a supportive and inclusive community.</li>
                                                <li><i className="fa fa-check"></i>Developing ethical, respectful, and engaged citizens for the future.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Our Vision</h4>
                                            <p className="tab-desc">Our vision is to be a leading educational institution recognized for academic excellence and innovative teaching methods. We strive to create a dynamic learning environment that fosters creativity, critical thinking, and a passion for knowledge.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Striving for academic excellence in every aspect.</li>
                                                <li><i className="fa fa-check"></i>Cultivating a culture of creativity and critical thinking.</li>
                                                <li><i className="fa fa-check"></i>Embracing technology and innovation to enhance educational experiences.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">Our Ranking</h4>
                                            <p className="tab-desc">JK English School consistently ranks among the top schools in the region. Our commitment to student success has earned us a reputation for outstanding educational standards and achievements.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Recognized for our high academic standards and student performance.</li>
                                                <li><i className="fa fa-check"></i>Consistently ranked among the top schools in the region.</li>
                                                <li><i className="fa fa-check"></i>Committed to maintaining and improving our excellent educational outcomes.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="research">
                                            <h4 className="tab-title">Our Research</h4>
                                            <p className="tab-desc">We are dedicated to advancing education through research and innovation. Our faculty and students engage in cutting-edge research projects that contribute to the field of education and improve teaching and learning outcomes.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Engaging in pioneering research to advance educational practices.</li>
                                                <li><i className="fa fa-check"></i>Supporting faculty and student-led research initiatives.</li>
                                                <li><i className="fa fa-check"></i>Collaborating with leading educational institutions and organizations worldwide.</li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TabBox
